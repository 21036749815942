<template>
  <header-component :show-logo="false"/>
  <intro-section/>
  <div class="container">
    <address-section/>
    <timeline-section/>
  </div>
</template>

<script>
import AddressSection from "@/components/AddressSection.vue";
import IntroSection from "@/components/IntroSection.vue";
import {apiPublicAxios} from "@/service/apiClient";
import {store} from '@/store';
import TimelineSection from "@/components/TimelineSection.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  components: {HeaderComponent, TimelineSection, IntroSection, AddressSection},
  data() {
    return {
      invitationError: false,
    }
  },
  mounted() {
    if (this.$route.query.i) {
      this.authenticate(this.$route.query.i);
    } else if(localStorage.getItem('auth')) {
      this.reauthenticate(localStorage.getItem('auth'));
    }
  },
  methods: {
    authenticate(invitationCode) {
      this.invitationError = false;
      apiPublicAxios.get('/login/' + invitationCode)
        .then(data => {
          store.commit('setAuth', data.data.token);
          store.commit('setAuthError', null);
          this.$root.$i18n.locale = store.state.language;
        })
        .catch(error => {
          this.invitationError = true;
          console.error(error);
          store.commit('setAuthError', error)
        })
    },
    reauthenticate(token) {
      store.commit('setAuth', token);
      store.commit('setAuthError', null);
    }
  }
}
</script>