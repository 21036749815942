export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página principal"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar asistencia"])},
        "accomodations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoteles cercanos"])},
        "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos"])},
        "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haznos un regalo"])}
      },
      "ca": {
        "main": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pàgina principal"])},
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar assistència"])},
        "accomodations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels propers"])},
        "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos"])},
        "present": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fes-nos un regal"])}
      }
    }
  })
}
