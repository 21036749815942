// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/prohibitions.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rules-content[data-v-e81c5198]{display:flex;gap:1em;align-items:center}.rules-content .icon-column[data-v-e81c5198]{flex-grow:0;flex-shrink:0}.rules-content .icon-column .icon[data-v-e81c5198]{display:inline-block;width:128px;height:128px}.rules-content .icon-column .icon.icon-prohibitions[data-v-e81c5198]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.rules-content .text-column p[data-v-e81c5198]:first-child{margin-top:0}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
