export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haznos un regalo"])},
        "phrase-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay tres cosas en la vida: salud, dinero y amor."])},
        "phrase-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afortunadamente nos sobran salud y amor."])}
      },
      "ca": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fes-nos un regal"])},
        "phrase-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha tres coses a la vida: salut, diners i amor."])},
        "phrase-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afortunadament ens sobren salut i amor."])}
      }
    }
  })
}
