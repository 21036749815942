<template>
  <div v-if="weather" class="weather">
    <div class="weather-column">
      <h1>{{ $t('afternoon') }}</h1>
      <div class="icon">{{ iconForWeather(weather.afternoon.weather, 'afternoon') }}</div>
      <p>{{ weather.afternoon.temperature }}ºC | PP: {{ weather.afternoon.precipitationProbability }}%</p>
    </div>
    <div class="weather-column">
      <h1>{{ $t('night') }}</h1>
      <div class="icon">{{ iconForWeather(weather.night.weather, 'night') }}</div>
      <p>{{ weather.night.temperature }}ºC | PP: {{ weather.night.precipitationProbability }}%</p>
    </div>
  </div>
</template>

<script>
import {apiPublicAxios} from "@/service/apiClient";

export default {
  data() {
    return {
      weather: null,
    }
  },
  mounted() {
    this.getWeather();
  },
  methods: {
    getWeather() {
      apiPublicAxios
        .get('/weather')
        .then(data => {
          this.weather = data.data;
        })
        .catch(() => {
          this.weather = null;
        })
    },
    iconForWeather(weather, time) {
      switch (weather) {
        case "clear":
          return time === 'afternoon' ? "☀️" : "🌙";
        case "mainly_clear":
          return "🌤️";
        case "partly_cloudy":
          return "⛅";
        case "overcast":
          return "☁️";
        case "fog":
          return "🌫️";
        case "drizzle":
          return "🌦️";
        case "rain":
          return "🌧️";
        case "snow":
          return "🌨️";
        case "rain_showers":
          return "⛈️";
        case "thunderstorm":
          return "🌩️";
        default:
          return "☀️";
      }
    }
  }
}
</script>

<i18n>
{
  "es": {
    "afternoon": "Tarde",
    "night": "Noche"
  },
  "ca": {
    "afternoon": "Tarda",
    "night": "Nit"
  }
}
</i18n>

<style scoped lang="scss">
.weather {
  justify-content: center;
  display: flex;
  gap: 24px;
  width: 100%;

  .weather-column {
    h1 {
      font-size: 14px;
      margin: 0;
    }

    .icon {
      font-size: 24px;
    }

    p {
      margin: 6px 0 0 0;
      font-size: 12px;
    }
  }
}
</style>