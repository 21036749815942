<template>
  <div class="section">
    <h1>{{$t('title')}}</h1>
    <div class="accomodation">
      <h1>{{$t('hotel-1-name')}}</h1>
      <p>
        {{$t('hotel-1-address')}}
      </p>
      <p>{{$t('hotel-1-booking')}} <a href="https://somiatruites.eu" target="_blank">{{$t('webpage')}}</a></p>
      <p>{{$t('hotel-1-price')}}</p>
    </div>
    <div class="accomodation">
      <h1>{{$t('hotel-2-name')}}</h1>
      <p>
        {{$t('hotel-2-address')}}
      </p>
      <p>{{$t('hotel-2-booking')}} <a href="https://www.booking.com/hotel/es/robert.ca.html" target="_blank">{{$t('booking')}}</a></p>
      <p>{{$t('hotel-2-price')}}</p>
    </div>
    <div class="accomodation">
      <h1>{{$t('hotel-3-name')}}</h1>
      <p>
        {{$t('hotel-3-address')}}
      </p>
      <p>{{$t('hotel-3-booking')}} <a href="https://hotel-bruc.es/" target="_blank">{{$t('booking')}}</a></p>
      <p>{{$t('hotel-3-price')}}</p>
    </div>
  </div>
</template>

<i18n>
{
  "es": {
    "title": "Hoteles Cercanos",
    "hotel-1-name": "Hotel Somiatruites",
    "hotel-1-address": "Carrer del Sol 19, Igualada (a 11 min en coche)",
    "hotel-1-booking": "Puedes reservar por teléfono al 93 803 66 26 o desde su",
    "hotel-1-price": "Precio aproximado: 125€/noche",
    "hotel-2-name": "Hotel Robert",
    "hotel-2-address": "Av. Catalunya 1, La Pobla de Claramunt (a 11 min en coche)",
    "hotel-2-booking": "Puedes reservar por teléfono al 93 808 60 00 o desde",
    "hotel-2-price": "Precio aproximado: 95€/noche",
    "hotel-3-name": "Hotel Bruc",
    "hotel-3-address": "Autopista A-2 km 570, El Bruc (a 11 min en coche)",
    "hotel-3-booking": "Puedes reservar por",
    "hotel-3-price": "Precio aproximado: 76€/noche",
    "booking": "Booking",
    "webpage": "página web"
  },
  "ca": {
    "title": "Hotels Propers",
    "hotel-1-name": "Hotel Somiatruites",
    "hotel-1-address": "Carrer del Sol 19, Igualada (a 11 min en cotxe)",
    "hotel-1-booking": "Pots reservar per telèfon al 93 803 66 26 o des de la seva",
    "hotel-1-price": "Preu aproximat: 125€/nit",
    "hotel-2-name": "Hotel Robert",
    "hotel-2-address": "Av. Catalunya 1, La Pobla de Claramunt (a 11 min en cotxe)",
    "hotel-2-booking": "Pots reservar per telèfon al 93 808 60 00 o des de",
    "hotel-2-price": "Preu aproximat: 95€/nit",
    "hotel-3-name": "Hotel Bruc",
    "hotel-3-address": "Autopista A-2 km 570, El Bruc (a 11 min en cotxe)",
    "hotel-3-booking": "Pots reservar per",
    "hotel-3-price": "Preu aproximat: 76€/nit",
    "booking": "Booking",
    "webpage": "pàgina web"
  }
}
</i18n>

<script>

export default {
  computed: {

  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/breakpoints";

.accomodation {
  h1 {
    text-align: left;
    font-weight: bold;
    font-size: 17px;
    text-transform: none;
    letter-spacing: normal;
  }
}
</style>