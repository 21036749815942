// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/arrows.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirm[data-v-aaf7a844]{margin-top:0;margin-bottom:2em;display:flex;justify-content:center;align-items:center;gap:1em}@media(max-width:576px){.confirm[data-v-aaf7a844]{margin-bottom:0}}.confirm .arrow-left[data-v-aaf7a844],.confirm .arrow-right[data-v-aaf7a844]{width:48px;height:72px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@media(max-width:576px){.confirm .arrow-left[data-v-aaf7a844],.confirm .arrow-right[data-v-aaf7a844]{width:24px;height:36px;background-size:cover}}.confirm .arrow-right[data-v-aaf7a844]{transform:scaleX(-1);filter:FlipH;-ms-filter:\"FlipH\"}.confirm a[data-v-aaf7a844],.confirm button[data-v-aaf7a844]{display:inline-block;color:#744f57;background-color:#f9f5e7;font-size:38px;padding:8px 24px 12px 24px;font-family:\"8BitOperatorPlus\",monospace;text-transform:lowercase;text-decoration:none;min-width:150px}@media(max-width:576px){.confirm a[data-v-aaf7a844],.confirm button[data-v-aaf7a844]{font-size:16px}}.confirm a[data-v-aaf7a844]:hover,.confirm button[data-v-aaf7a844]:hover{background-color:#744f57;color:#f8ead8;transition:0ms}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
