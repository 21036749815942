export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "cocktail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cóctel de bienvenida"])},
        "ceremony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremonia civil"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, quiero"])},
        "appetizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperitivos"])},
        "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena"])},
        "cake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarta nupcial"])},
        "dj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra libre + DJ"])},
        "game-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de fiesta"])}
      },
      "ca": {
        "cocktail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Còctel de benvinguda"])},
        "ceremony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerimònia civil"])},
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, vull"])},
        "appetizer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperitius"])},
        "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopar"])},
        "cake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastís nupcial"])},
        "dj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barra lliure + DJ"])},
        "game-over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fi de festa"])}
      }
    }
  })
}
