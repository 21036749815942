export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "will-you-come": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", ¿vendrás?"])},
        "full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])},
        "allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Tienes alguna alergia, intolerancia o dieta especial?"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres comentarnos algo más?"])},
        "coming-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
        "coming-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "coming-dont-know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todavía no lo sé"])},
        "error-full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce tu nombre completo para poder continuar"])}
      },
      "ca": {
        "will-you-come": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", vindràs?"])},
        "full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
        "allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tens alguna al·lèrgia, intol·lerància o dieta especial?"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vols dir-nos alguna cosa més?"])},
        "coming-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
        "coming-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "coming-dont-know": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encara no ho sé"])},
        "error-full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introdueix el teu nom complet per a poder continuar"])}
      }
    }
  })
}
