<template>
  <p>{{$t('text')}}</p>
</template>

<i18n>
{
  "es": {
    "text": "Saliendo..."
  },
  "ca": {
    "text": "Sortint..."
  }
}
</i18n>

<script>
import {store} from "@/store";
export default {
  mounted() {
    store.commit('clearAuth');
    this.$router.push('/');
  }
}
</script>

<style scoped lang="scss">

</style>