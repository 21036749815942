<template>
  <div class="confirm">
    <div class="arrow-left"></div>
    <div>
      <a v-if="isWeddingDay" href="https://galeria.lorena.carles.cloud" target="_blank">{{$t('gallery')}}</a>
      <button v-else @click="$router.push('/registration')">{{$t('confirm')}}</button>
    </div>
    <div class="arrow-right"></div>
  </div>
</template>

<i18n>
{
  "es": {
    "confirm": "Confirmar Asistencia",
    "gallery": "Ver fotos"
  },
  "ca": {
    "confirm": "Confirmar Assistència",
    "gallery": "Veure fotos"
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      currentTime: (new Date()).getTime(),
      weddingTime: (new Date('2024-06-29T17:30:00')).getTime(),
    }
  },
  computed: {
    remainingTime() {
      return this.weddingTime - this.currentTime;
    },
    isWeddingDay() {
      return this.remainingTime < 43200000;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/breakpoints";

.confirm {
  margin-top: 0;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;

  @include sm {
    margin-bottom: 0;
  }

  .arrow-left, .arrow-right {
    width: 48px;
    height: 72px;
    background: url('@/assets/img/arrows.gif');

    @include sm {
      width: 24px;
      height: 36px;
      background-size: cover;
    }
  }

  .arrow-right {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }

  button, a {
    display: inline-block;
    color: $peachDark;
    background-color: $beigeLight;
    font-size: 38px;
    padding: 8px 24px 12px 24px;
    font-family: '8BitOperatorPlus', monospace;
    text-transform: lowercase;
    text-decoration: none;
    min-width: 150px;

    @include sm {
      font-size: 16px;
    }

    &:hover {
      background-color: $peachDark;
      color: $beige;
      transition: 0ms;
    }
  }
}


</style>