export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando invitados..."])},
        "error-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no se han podido guardar tus datos, pero estamos deseando que vengas."])},
        "error-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, contacta con nosotros y lo solucionaremos."])},
        "still-dont-know-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, pedimos que confirmes tu asistencia antes del 1 de Mayo de 2024."])},
        "form-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hay errores en el formulario. Por favor, corrígelos antes de poder seguir."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
      },
      "ca": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregant convidats..."])},
        "error-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, no s'han pogut guardar les teves dades, però estem desitjant que vinguis."])},
        "error-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, contacta amb nosaltres i ho solucionarem."])},
        "still-dont-know-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, demanem que confirmis la teva assistència abans de l'1 de Maig de 2024."])},
        "form-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi ha errors al formulari. Si us plau, corregeix-los abans de continuar."])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar"])}
      }
    }
  })
}
