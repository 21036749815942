export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando invitados..."])},
        "form-is-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formulario para confirmar tu asistencia ya está cerrado. Sin embargo, si necesitas hacer algún cambio, nos puedes escribir directamente 😊"])},
        "your-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuestros datos"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
        "coming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asistirá"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edad"])},
        "full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre completo"])},
        "allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alergias"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentarios"])},
        "age-adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adulto/a"])},
        "age-kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niño/a"])},
        "age-baby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bebé"])},
        "coming-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
        "coming-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
      },
      "ca": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregant convidats..."])},
        "form-is-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formulari per a confirmar la teva assistència ja està tancat. Tot i així, si necessites fer algun canvi, ens pots escriure directament 😊"])},
        "your-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vostres dades"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "coming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hi assisteix"])},
        "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edat"])},
        "full-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
        "allergies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al·lèrgies"])},
        "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentaris"])},
        "age-adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult/a"])},
        "age-kid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nen/a"])},
        "age-baby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bebé"])},
        "coming-yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
        "coming-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
      }
    }
  })
}
