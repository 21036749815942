<template>
  <header-component/>
  <div class="container-small">
    <div class="section">
      <div class="alert">
        {{ $t('form-is-closed') }}
      </div>
    </div>

    <div v-if="loading" class="loading section">
      <characters-loading/>
      {{ $t('loading') }}
    </div>
    <div v-else class="section">
      <h1>{{ $t('your-data') }}</h1>
      <div class="guest" v-for="guest in guests" :key="guest.id">
        {{ $t('name') }}: <b>{{ guest.name }}</b><br/>
        {{ $t('coming') }}: <b>{{ guest.coming ? $t('coming-yes') : $t('coming-no') }}</b><br/>
        {{ $t('age') }}: <b>{{ $t('age-' + guest.type) }}</b><br/>
        <template v-if="guest.coming">
          {{ $t('full-name') }}: <b>{{ guest.fullName || '-' }}</b><br/>
          {{ $t('allergies') }}: <b>{{ guest.allergies || '-' }}</b><br/>
          {{ $t('note') }}: <b>{{ guest.note || '-' }}</b><br/>
        </template>
      </div>
    </div>

    <present-component/>
    <rules-component/>
  </div>
</template>

<i18n>
{
  "es": {
    "loading": "Cargando invitados...",
    "form-is-closed": "El formulario para confirmar tu asistencia ya está cerrado. Sin embargo, si necesitas hacer algún cambio, nos puedes escribir directamente 😊",
    "your-data": "Vuestros datos",
    "name": "Nombre",
    "coming": "Asistirá",
    "age": "Edad",
    "full-name": "Nombre completo",
    "allergies": "Alergias",
    "note": "Comentarios",
    "age-adult": "Adulto/a",
    "age-kid": "Niño/a",
    "age-baby": "Bebé",
    "coming-yes": "Sí",
    "coming-no": "No",
  },
  "ca": {
    "loading": "Carregant convidats...",
    "form-is-closed": "El formulari per a confirmar la teva assistència ja està tancat. Tot i així, si necessites fer algun canvi, ens pots escriure directament 😊",
    "your-data": "Les vostres dades",
    "name": "Nom",
    "coming": "Hi assisteix",
    "age": "Edat",
    "full-name": "Nom complet",
    "allergies": "Al·lèrgies",
    "note": "Comentaris",
    "age-adult": "Adult/a",
    "age-kid": "Nen/a",
    "age-baby": "Bebé",
    "coming-yes": "Sí",
    "coming-no": "No",
  }
}
</i18n>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import {apiAxios} from "@/service/apiClient";
import CharactersLoading from "@/components/CharactersLoading.vue";
import PresentComponent from "@/components/PresentComponent.vue";
import RulesComponent from "@/components/RulesComponent.vue";

export default {
  components: {CharactersLoading, HeaderComponent, PresentComponent, RulesComponent},
  data() {
    return {
      loading: false,
      guests: [],
    }
  },
  mounted() {
    this.getGuests();
  },
  methods: {
    getGuests() {
      this.loading = true;
      apiAxios.get('/guests')
        .then(data => {
          this.guests = data.data;
        })
        .finally(() => {
          this.loading = false;
        })
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";

.alert {
  margin-bottom: 0;
}

.guest {
  +.guest {
    margin-top: 1em;
  }
}
</style>