// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/present.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".present-content[data-v-bf207858]{display:flex;gap:1em;align-items:center}.present-content .icon-column[data-v-bf207858]{flex-grow:0;flex-shrink:0}.present-content .icon-column .icon[data-v-bf207858]{display:inline-block;width:128px;height:128px}.present-content .icon-column .icon.icon-present[data-v-bf207858]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.present-content .text-column p[data-v-bf207858]:first-child{margin-top:0}.iban[data-v-bf207858]{display:block;border:1px solid #744f57;padding:8px 16px;background:#fff;color:#744f57;font-size:18px}@media(max-width:576px){.iban.iban-desktop[data-v-bf207858]{display:none}}.iban.iban-mobile[data-v-bf207858]{margin-top:1em;display:none;text-align:center}@media(max-width:576px){.iban.iban-mobile[data-v-bf207858]{display:block}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
