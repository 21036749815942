import {createApp} from 'vue'
import App from './App.vue'
import {store} from '@/store';
import router from '@/router/router';
import {createI18n} from 'vue-i18n';

const i18n = createI18n({
    legacy: true,
    locale: 'ca',
    fallbackLocale: 'ca',
})

createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .mount('#app')
