<template>
  <header>
    <div v-if="showLogo" class="logo" @click="goToMain"></div>
    <div class="spacing"></div>
    <div class="menu-icon" @click="toggleMenuOpenForMobile">
      <img src="@/assets/img/menu.svg" alt=""/>
    </div>
    <div class="options" :class="{'mobile-open': menuMobileOpen}">
      <div class="option" @click="goToMain">{{$t('main')}}</div>
<!--      <div class="option" @click="goToRegistration">{{$t('confirm')}}</div>-->
      <a class="option" href="https://galeria.lorena.carles.cloud" target="_blank">{{$t('gallery')}}</a>
      <div class="option" @click="goToAccomodations">{{$t('accomodations')}}</div>
      <div class="option" @click="goToPresent">{{$t('present')}}</div>
      <div v-if="familyUnit" class="username">
        {{ familyUnit }}
        <div class="logout-icon" @click="$router.push('/logout')"></div>
      </div>
    </div>
  </header>
  <div class="header-offset"></div>
</template>

<i18n>
{
  "es": {
    "main": "Página principal",
    "confirm": "Confirmar asistencia",
    "accomodations": "Hoteles cercanos",
    "gallery": "Fotos",
    "present": "Haznos un regalo"
  },
  "ca": {
    "main": "Pàgina principal",
    "confirm": "Confirmar assistència",
    "accomodations": "Hotels propers",
    "gallery": "Fotos",
    "present": "Fes-nos un regal"
  }
}
</i18n>

<script>
import {store} from "@/store";

export default {
  props: {
    showLogo: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      menuMobileOpen: false,
    }
  },
  computed: {
    familyUnit() {
      if (!store.state.auth) {
        return null;
      }
      return store.state.name;
    }
  },
  methods: {
    goToMain() {
      this.$router.push('/');
    },
    goToRegistration() {
      this.$router.push('/registration');
    },
    goToPresent() {
      this.$router.push('/present');
    },
    goToAccomodations() {
      this.$router.push('/accomodations');
    },
    toggleMenuOpenForMobile() {
      this.menuMobileOpen = !this.menuMobileOpen;
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import '@/assets/scss/breakpoints';

.header-offset {
  height: 57px;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $beigeDark;
  min-height: 57px;
  z-index: 2;

  .menu-icon {
    display: none;

    img {
      width: 32px;
      margin-right: 16px;
      cursor: pointer;
    }

    @include lg {
      display: block;
    }
  }

  .options {
    display: flex;
    align-items: center;

    @include lg {
      display: none;
      position: fixed;
      top: 57px;
      width: 100%;
      align-items: normal;
      flex-direction: column;
      gap: 1em;
      background-color: $beigeDark;
      padding: 1em 0;

      &.mobile-open {
        display: flex;
      }
    }

    .option {
      flex-grow: 0;
      flex-shrink: 0;
      margin-left: 32px;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $peach;
      text-decoration: none;

      &:last-child {
        margin-right: 32px;
      }

      &:hover {
        color: $peachDark;
      }
    }
  }

  .username {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: $peach;
    margin-left: 16px;
    padding-left: 16px;
    margin-right: 32px;
    border-left: 1px solid $peach;

    @include lg {
      border-left: none;
    }

    .logout-icon {
      width: 24px;
      height: 24px;
      background: url('@/assets/img/logout.svg');
      background-size: cover;
      cursor: pointer;
    }
  }

  .spacing {
    flex-grow: 1;
  }

  .logo {
    margin-left: 1em;
    width: 180px;
    height: 50px;
    background-image: url('@/assets/img/logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @include sm {
      height: 48px;
    }
  }
}
</style>