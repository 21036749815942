// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/novios.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".characters[data-v-38b28e0f]{margin-top:1em;display:flex;justify-content:center}.characters .lorena[data-v-38b28e0f]{width:256px;height:256px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:0}@media(max-width:576px){.characters .lorena[data-v-38b28e0f]{background-size:200%;width:128px;height:128px}}.characters .carles[data-v-38b28e0f]{margin-left:-48px;width:256px;height:256px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:256px}@media(max-width:576px){.characters .carles[data-v-38b28e0f]{background-size:200%;width:128px;height:128px;background-position-x:128px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
