export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muchas gracias"])},
        "phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus datos se han guardado correctamente. Nos vemos pronto :)"])}
      },
      "ca": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moltes gràcies"])},
        "phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les teves dades s'han guardat correctament. Ens veiem aviat :)"])}
      }
    }
  })
}
