<template>
  <header-component/>
  <div class="container-small">
    <div v-if="loading" class="loading section">
      <characters-loading/>
      {{ $t('loading') }}
    </div>

    <guest-form
      v-for="guest in guests"
      :key="guest.id"
      :guest="guest"
      v-model:coming="guest.coming"
      v-model:full-name="guest.fullName"
      v-model:allergies="guest.allergies"
      v-model:note="guest.note"
      :error-full-name="firstSubmit && guest.coming === true && guest.fullName === ''"
    />

    <div class="section">
      <div v-if="savingError" class="alert">
        {{ $t('error-1') }}<br/>
        {{ $t('error-2') }}
      </div>
      <div v-if="stillDontKnow" class="alert brown">
        {{ $t('still-dont-know-alert') }}
      </div>
      <div class="save">
        <characters-loading v-if="saving"/>
        <button @click="saveGuests" :class="{disabled: saving}" :disabled="saving">{{ $t('submit') }}</button>
      </div>
      <div v-if="formErrors" class="form-error">
        {{ $t('form-error') }}
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "es": {
    "loading": "Cargando invitados...",
    "error-1": "Lo sentimos, no se han podido guardar tus datos, pero estamos deseando que vengas.",
    "error-2": "Por favor, contacta con nosotros y lo solucionaremos.",
    "still-dont-know-alert": "Por favor, pedimos que confirmes tu asistencia antes del 1 de Mayo de 2024.",
    "form-error": "Hay errores en el formulario. Por favor, corrígelos antes de poder seguir.",
    "submit": "Enviar"
  },
  "ca": {
    "loading": "Carregant convidats...",
    "error-1": "Ho sentim, no s'han pogut guardar les teves dades, però estem desitjant que vinguis.",
    "error-2": "Si us plau, contacta amb nosaltres i ho solucionarem.",
    "still-dont-know-alert": "Si us plau, demanem que confirmis la teva assistència abans de l'1 de Maig de 2024.",
    "form-error": "Hi ha errors al formulari. Si us plau, corregeix-los abans de continuar.",
    "submit": "Enviar"
  }
}
</i18n>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import {apiAxios} from "@/service/apiClient";
import GuestForm from "@/components/GuestForm.vue";
import CharactersLoading from "@/components/CharactersLoading.vue";

export default {
  components: {CharactersLoading, GuestForm, HeaderComponent},
  data() {
    return {
      loading: false,
      saving: false,
      savingError: false,
      guests: [],
      firstSubmit: false,
      formErrors: false,
    }
  },
  mounted() {
    this.getGuests();
    this.$router.push('/registration-over');
  },
  computed: {
    stillDontKnow() {
      return this.guests.filter(g => g.coming === null).length > 0;
    }
  },
  methods: {
    getGuests() {
      this.loading = true;
      apiAxios.get('/guests')
        .then(data => {
          this.guests = data.data;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    saveGuests() {
      this.firstSubmit = true;
      this.formErrors = false;

      for (const guest of this.guests) {
        if (guest.coming === true && guest.fullName === '') {
          this.formErrors = true;
          return;
        }
      }

      this.saving = true;
      this.savingError = false;

      apiAxios.put('/guests', {guests: this.guests})
        .then(() => {
          this.$router.push('/registration-ok');
        })
        .catch((error) => {
          this.savingError = true;
          console.error(error);
        })
        .finally(() => {
          this.saving = false;
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";

.loading {
  display: flex;
  align-items: center;
}

.save {
  display: flex;
  align-items: center;
  justify-content: right;
}

.form-error {
  display: block;
  color: $red;
  font-size: 12px;
}
</style>