export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Nos casamos!"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29 de Junio de 2024"])},
        "error-loading-invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Lo sentimos! No hemos podido cargar tu invitación. Por favor, contacta con nosotros."])}
      },
      "ca": {
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ens casem!"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29 de Juny de 2024"])},
        "error-loading-invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim! No hem pogut carregar la teva invitació. Si us plau, contacta amb nosaltres."])}
      }
    }
  })
}
