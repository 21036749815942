// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/novios_small.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".characters[data-v-8e97a33a]{display:inline-flex;justify-content:center}.characters .lorena[data-v-8e97a33a]{width:32px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:0}.characters .carles[data-v-8e97a33a]{width:32px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:32px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
