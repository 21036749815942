// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/logout.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/img/logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-offset[data-v-9694a082]{height:57px}header[data-v-9694a082]{position:fixed;top:0;left:0;width:100%;display:flex;align-items:center;background-color:#eddbc7;min-height:57px;z-index:2}header .menu-icon[data-v-9694a082]{display:none}header .menu-icon img[data-v-9694a082]{width:32px;margin-right:16px;cursor:pointer}@media(max-width:1024px){header .menu-icon[data-v-9694a082]{display:block}}header .options[data-v-9694a082]{display:flex;align-items:center}@media(max-width:1024px){header .options[data-v-9694a082]{display:none;position:fixed;top:57px;width:100%;align-items:normal;flex-direction:column;gap:1em;background-color:#eddbc7;padding:1em 0}header .options.mobile-open[data-v-9694a082]{display:flex}}header .options .option[data-v-9694a082]{flex-grow:0;flex-shrink:0;margin-left:32px;cursor:pointer;text-transform:uppercase;letter-spacing:2px;color:#a7727d;text-decoration:none}header .options .option[data-v-9694a082]:last-child{margin-right:32px}header .options .option[data-v-9694a082]:hover{color:#744f57}header .username[data-v-9694a082]{display:inline-flex;align-items:center;gap:8px;color:#a7727d;margin-left:16px;padding-left:16px;margin-right:32px;border-left:1px solid #a7727d}@media(max-width:1024px){header .username[data-v-9694a082]{border-left:none}}header .username .logout-icon[data-v-9694a082]{width:24px;height:24px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;cursor:pointer}header .spacing[data-v-9694a082]{flex-grow:1}header .logo[data-v-9694a082]{margin-left:1em;width:180px;height:50px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-size:contain;background-repeat:no-repeat}@media(max-width:576px){header .logo[data-v-9694a082]{height:48px}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
