<template>
  <div class="characters" :style="{transform: 'translateY(-' + positionY + 'px)'}">
    <div class="lorena" :style="{backgroundPositionY: backgroundOffsetY + 'px'}"></div>
    <div class="carles" :style="{backgroundPositionY: backgroundOffsetY + 'px'}"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      elapsedTime: 0,
      interval: null,
    }
  },
  computed: {
    positionY() {
      return Math.max(0, Math.sin(this.elapsedTime * 2) * 16);
    },
    backgroundOffsetY() {
      return this.positionY === 0 ? 32 : 0;
    }
  },
  mounted() {
    if (this.interval === null) {
      this.interval = setInterval(() => {
        this.elapsedTime += 50;
      }, 50);
    }
  },
  unmounted() {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  methods() {

  }
}
</script>

<style scoped lang="scss">
.characters {
  display: inline-flex;
  justify-content: center;

  .lorena {
    width: 32px;
    height: 32px;
    background-image: url('@/assets/img/novios_small.png');
    background-position-x: 0;
  }

  .carles {
    width: 32px;
    height: 32px;
    background-image: url('@/assets/img/novios_small.png');
    background-position-x: 32px;
  }
}
</style>