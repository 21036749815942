export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "date-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha y hora"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado 29 de junio de 2024"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17:30"])},
        "how-to-get-there": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Cómo llegar?"])},
        "place-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can Macià - Cavas Bohigas"])},
        "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
        "a2-exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-2, Salida 557"])},
        "bv-1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carretera BV-1106, km 2"])},
        "odena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08711 Òdena (Barcelona)"])}
      },
      "ca": {
        "date-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data i hora"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dissabte 29 de juny de 2024"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17:30"])},
        "how-to-get-there": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com arribar?"])},
        "place-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can Macià - Caves Bohigas"])},
        "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreça"])},
        "a2-exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A-2, Sortida 557"])},
        "bv-1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carretera BV-1106, km 2"])},
        "odena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08711 Òdena (Barcelona)"])}
      }
    }
  })
}
