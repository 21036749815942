<template>
    <header-component/>
    <div class="container-small">
        <accomodations-component/>
    </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import AccomodationsComponent from "@/components/AccomodationsComponent.vue";

export default {
    components: {AccomodationsComponent, HeaderComponent}
}
</script>

<style scoped lang="scss">

</style>