import {createStore} from 'vuex';
import jwtDecode from "jwt-decode";

export const store = createStore({
    state() {
        return {
            auth: localStorage.getItem('auth'),
            name: null,
            language: localStorage.getItem('language') ?? 'ca',
            authError: false,
            iban: null,
        }
    },

    actions: {
        setAuth({commit}, payload) {
            commit("setAuth", payload);
        },
        clearAuth({commit}, payload) {
            commit("clearAuth", payload);
        },
        setAuthError({commit}, payload) {
            commit("setAuthError", payload);
        },
        setIban({commit}, iban) {
            commit("setIban", iban);
        },
    },

    mutations: {
        setAuth(state, auth) {
            state.auth = auth;
            localStorage.setItem('auth', state.auth);
            let decoded = jwtDecode(auth);
            state.name = decoded.name;
            state.language = decoded.language;
            localStorage.setItem('language', state.language);
        },
        clearAuth(state) {
            state.auth = null;
            localStorage.removeItem('auth');
        },
        setAuthError(state, error) {
            state.authError = error;
        },
        setIban(state, iban) {
            state.iban = iban;
        },
    }
});