<template>
    <header-component/>
    <div class="container-small">
        <present-component/>
    </div>
</template>

<script>
import PresentComponent from "@/components/PresentComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
    components: {HeaderComponent, PresentComponent}
}
</script>

<style scoped lang="scss">

</style>