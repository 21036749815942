<template>
  <div class="intro-section">
    <div v-if="familyUnit" class="username">
      {{ familyUnit }}
      <div class="logout-icon" @click="$router.push('/logout')"></div>
    </div>
    <div class="logo"></div>
    <h2>{{ $t('subtitle') }}</h2>
    <h3>{{ $t('date') }}</h3>
    <div v-if="authError" class="alert auth-error">
      {{ $t('error-loading-invitation') }}
    </div>
    <loader-component/>
    <characters-component/>
    <confirm-component/>
  </div>
</template>

<i18n>
{
  "es": {
    "subtitle": "¡Nos casamos!",
    "date": "29 de Junio de 2024",
    "error-loading-invitation": "¡Lo sentimos! No hemos podido cargar tu invitación. Por favor, contacta con nosotros."
  },
  "ca": {
    "subtitle": "Ens casem!",
    "date": "29 de Juny de 2024",
    "error-loading-invitation": "Ho sentim! No hem pogut carregar la teva invitació. Si us plau, contacta amb nosaltres."
  }
}
</i18n>

<script>
import CharactersComponent from "@/components/CharactersComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import {store} from "@/store";
import ConfirmComponent from "@/components/ConfirmComponent.vue";

export default {
  components: {
    ConfirmComponent,
    CharactersComponent,
    LoaderComponent
  },
  computed: {
    familyUnit() {
      if (!store.state.auth) {
        return null;
      }
      return store.state.name;
    },
    authError() {
      return store.state.authError;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";
@import "../assets/scss/breakpoints";

.intro-section {
  background-color: $beigeDark;
  color: $peachDark;
  text-align: center;
  overflow: hidden;
  padding: 4em 0 2em 0;

  .username {
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 16px;
    right: 32px;
    color: $peach;
    display: none;

    @include sm {
      display: inline-flex;
      font-size: 14px;
      top: 18px;
      right: 64px;
      z-index: 3;
    }

    .logout-icon {
      width: 24px;
      height: 24px;
      background: url('@/assets/img/logout.svg');
      background-size: cover;
      cursor: pointer;

      @include sm {
        width: 18px;
        height: 18px;
        font-size: 14px;
      }
    }
  }

  .logo {
    width: 100%;
    height: 84px;
    background-image: url('@/assets/img/logo.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @include sm {
      height: 48px;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin: 2em 0;

    @include sm {
      font-size: 16px;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: 300;
    margin: 2em 0;
    display: none;

    @include sm {
      display: block;
    }
  }

  .auth-error {
    max-width: 1000px;
    margin: 10px auto;
  }
}

</style>
