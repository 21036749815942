export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saliendo..."])}
      },
      "ca": {
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortint..."])}
      }
    }
  })
}
