<template>
    <div class="radio-options">
        <radio-option
                v-for="option in options"
                :key="option.value"
                :option="option"
                :selected="localValue === option.value"
                @select="onSelect"
        />
    </div>
</template>

<script>
import RadioOption from "@/components/RadioOption.vue";

export default {
    components: {RadioOption},
    emits: ['update:modelValue'],
    props: {
        options: {
            type: Array,
            required: true,
        },
        modelValue: {}
    },
    data() {
        return {
            localValue: this.modelValue
        }
    },
    methods: {
        onSelect(v) {
            this.localValue = v;
            this.$emit('update:modelValue', this.localValue)
        }
    }
}
</script>

<style scoped lang="scss">

</style>