<template>
  <div class="section">
    <h1>{{$t('title')}}</h1>
    <div class="present-content">
      <div class="icon-column">
        <div class="icon icon-present"></div>
      </div>
      <div class="text-column">
        <p>{{$t('phrase-1')}}</p>
        <p>{{$t('phrase-2')}}</p>
        <div class="iban iban-desktop">{{ ibanText }}</div>
      </div>
    </div>
    <div class="iban iban-mobile">{{ ibanText }}</div>
  </div>
</template>

<i18n>
{
  "es": {
    "title": "Haznos un regalo",
    "phrase-1": "Hay tres cosas en la vida: salud, dinero y amor.",
    "phrase-2": "Afortunadamente nos sobran salud y amor."
  },
  "ca": {
    "title": "Fes-nos un regal",
    "phrase-1": "Hi ha tres coses a la vida: salut, diners i amor.",
    "phrase-2": "Afortunadament ens sobren salut i amor."
  }
}
</i18n>

<script>
import {apiAxios} from "@/service/apiClient";
import {store} from "@/store";

export default {
  computed: {
    iban() {
      return store.state.iban;
    },
    ibanText() {
      if (!this.iban) {
        return 'Carregant...';
      }

      return this.iban;
    }
  },
  mounted() {
    if (!this.iban) {
      this.getInfo();
    }
  },
  methods: {
    getInfo() {
      apiAxios.get('/info')
        .then(data => {
          store.commit('setIban', data.data.iban);
        })
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/breakpoints";

.present-content {
  display: flex;
  gap: 1em;
  align-items: center;

  .icon-column {
    flex-grow: 0;
    flex-shrink: 0;

    .icon {
      display: inline-block;
      width: 128px;
      height: 128px;


      &.icon-present {
        background-image: url('@/assets/img/present.png');
      }
    }
  }

  .text-column {
    p:first-child {
      margin-top: 0;
    }
  }
}

.iban {
  display: block;
  border: 1px solid $peachDark;
  padding: 8px 16px;
  background: $white;
  color: $peachDark;
  font-size: 18px;

  &.iban-desktop {
    @include sm {
      display: none;
    }
  }

  &.iban-mobile {
    margin-top: 1em;
    display: none;
    text-align: center;
    @include sm {
      display: block;
    }
  }
}
</style>