// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/confirmation.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-content[data-v-03d09bda]{display:flex;gap:1em;align-items:normal}.login-content .icon-column[data-v-03d09bda]{flex-grow:0;flex-shrink:0}.login-content .icon-column .icon[data-v-03d09bda]{display:inline-block;width:128px;height:128px}.login-content .icon-column .icon.icon-confirmation[data-v-03d09bda]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.login-content .save[data-v-03d09bda]{display:flex;align-items:center;justify-content:right}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
