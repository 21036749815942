<template>
    <div class="loader">
      <template v-if="itsBeforeWedding">
        <h1>{{$t('loading')}}{{ dots }}</h1>
        <div class="loader-bar-container">
            <div class="loader-bar">
                <div class="loader-bar-progress" :style="{width: this.barPercentage + '%'}"></div>
            </div>
            <div class="loader-bar-date">{{$t('date')}}</div>
        </div>
        <h2>
           {{ $t('remaining')}}: <b>{{ remainingDays }}</b> {{$t('days')}}, <b>{{ remainingHours }}</b> {{$t('hours')}}, <b>{{ remainingMinutes }}</b>
          {{$t('minutes')}} {{$t('and')}} <b>{{ remainingSeconds }}</b> {{$t('seconds')}}
        </h2>
      </template>
      <h1 class="center phrase" v-else-if="itsWeddingTime">{{ $t('wedding-' + currentPhrase) }}</h1>
      <h1 class="center" v-else-if="itsPastWedding">{{ $t('it-was-amazing') }}</h1>
    </div>
</template>

<i18n>
{
  "es": {
    "loading": "Cargando bodorrio",
    "date": "29 de Junio de 2024",
    "remaining": "Quedan",
    "days": "días",
    "hours": "horas",
    "minutes": "minutos",
    "and": "y",
    "seconds": "segundos",
    "wedding-0": "¡Sí! ¡Quiero!",
    "wedding-1": "¡Lo estamos petando!",
    "wedding-2": "¡Queremos que la Lore nos baile la pelusa!",
    "wedding-3": "¡Que no pare la barra!",
    "wedding-4": "¡Que se besen! ¡Que se besen!",
    "it-was-amazing": "¡Fue increíble! ¡Gracias por venir!",
  },
  "ca": {
    "loading": "Carregant bodorrio",
    "date": "29 de Juny de 2024",
    "remaining": "Queden",
    "days": "dies",
    "hours": "hores",
    "minutes": "minuts",
    "and": "i",
    "seconds": "segons",
    "wedding-0": "Sí! Vull!",
    "wedding-1": "Ho estem gaudint!",
    "wedding-2": "¡Queremos que la Lore nos baile la pelusa!",
    "wedding-3": "Que no pari la barra!",
    "wedding-4": "¡Que se besen! ¡Que se besen!",
    "it-was-amazing": "Va ser impressionant! Gràcies per venir!",
  }
}
</i18n>

<script>

export default {
    data() {
        return {
            refreshInterval: null,
            dotsInterval: null,
            phraseInterval: null,
            currentTime: (new Date()).getTime(),
            weddingTime: (new Date('2024-06-29T17:30:00')).getTime(),
            barStartTime: (new Date('2023-12-01T00:00:00')).getTime(),
            dots: '',
            currentPhrase: 0,
        }
    },
    computed: {
        itsPastWedding() {
          return this.remainingTime <= -43200000;
        },
        itsWeddingTime() {
            return this.remainingTime < 0 && this.remainingTime > -43200000;
        },
        itsBeforeWedding() {
            return !this.itsPastWedding && !this.itsWeddingTime;
        },
        remainingTime() {
            return this.weddingTime - this.currentTime;
        },
        remainingDays() {
            return Math.floor(this.remainingTime / 86400000)
        },
        remainingHours() {
            return Math.floor((this.remainingTime % 86400000) / 3600000)
        },
        remainingMinutes() {
            return Math.floor((this.remainingTime % 3600000) / 60000)
        },
        remainingSeconds() {
            return Math.floor((this.remainingTime % 60000) / 1000)
        },
        barPercentage() {
            let totalBarTime = this.weddingTime - this.barStartTime;
            return 100 - ((this.remainingTime / totalBarTime) * 100);
        }
    },
    mounted() {
        this.refreshInterval = setInterval(() => {
            this.currentTime = (new Date()).getTime();
        }, 100);
        this.dotsInterval = setInterval(() => {
            this.refreshDots();
        }, 200);
        this.phraseInterval = setInterval(() => {
            this.nextPhrase();
        }, 3000);
    },
    unmounted() {
        if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
            this.refreshInterval = null;
        }

        if (this.dotsInterval) {
            clearInterval(this.dotsInterval);
            this.dotsInterval = null;
        }

        if (this.phraseInterval) {
            clearInterval(this.phraseInterval);
            this.phraseInterval = null;
        }
    },
    methods: {
        refreshDots() {
            if (this.dots === '') {
                this.dots = '.';
            } else if (this.dots === '.') {
                this.dots = '..';
            } else if (this.dots === '..') {
                this.dots = '...';
            } else if (this.dots === '...') {
                this.dots = '';
            }
        },
        nextPhrase() {
          this.currentPhrase = (this.currentPhrase + 1) % 5
        }
    }
}

</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/breakpoints";

.loader {
  display: inline-block;
  margin: 0 auto;
  text-align: left;
  font-family: '8BitOperatorPlus', monospace;
  text-transform: lowercase;

  @include sm {
    padding: 0 1em;
    width: 100%;
    box-sizing: border-box;
  }

  .loader-bar-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .loader-bar {
      border: 2px solid $peachDark;
      width: 400px;
      padding: 2px;

      @include sm {
        flex-grow: 1;
      }

      .loader-bar-progress {
        height: 20px;
        width: 50%;
        background: $peachDark;
      }
    }

    .loader-bar-date {
      flex-grow: 1;

      @include sm {
        display: none;
      }
    }
  }

  h1 {
    margin: 0 0 8px 0;
    font-weight: normal;
    font-size: 20px;

    @include sm {
      font-size: 16px;
    }

    &.center {
      text-align: center;
    }

    &.phrase {
      height: 3em;
    }
  }

  h2 {
    margin: 8px 0 0 0;
    font-weight: normal;
    font-size: 16px;

    @include sm {
      font-size: 10px;
    }
  }

  .loader-bar-date {
    font-weight: normal;
    font-size: 18px;
  }
}

</style>