// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/calendar.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/img/pin.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/img/windmill.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/img/highway.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".address-section[data-v-376ee2c4]{display:flex;width:100%;gap:64px;text-align:center;margin-top:2em}@media(max-width:1024px){.address-section[data-v-376ee2c4]{flex-direction:column;gap:32px}}.address-section .column[data-v-376ee2c4]{flex-grow:1;flex-basis:0;text-align:center}.address-section .column .circle[data-v-376ee2c4]{display:inline-block;background-color:#f9f5e7;width:280px;height:280px;border-radius:30px;border:1px solid #a7727d;font-weight:700}.address-section .column .circle.bigger[data-v-376ee2c4]{height:300px}.address-section .column .icon[data-v-376ee2c4]{margin-top:36px;display:inline-block;width:64px;height:64px}.address-section .column .icon.icon-calendar[data-v-376ee2c4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.address-section .column .icon.icon-pin[data-v-376ee2c4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.address-section .column .icon.icon-windmill[data-v-376ee2c4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.address-section .column .icon.icon-highway[data-v-376ee2c4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.address-section .column h1[data-v-376ee2c4]{font-size:20px;font-weight:300;text-transform:uppercase;letter-spacing:3px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
