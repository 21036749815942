export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando bodorrio"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29 de Junio de 2024"])},
        "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quedan"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["días"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["horas"])},
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y"])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundos"])},
        "wedding-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Sí! ¡Quiero!"])},
        "wedding-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Lo estamos petando!"])},
        "wedding-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Queremos que la Lore nos baile la pelusa!"])},
        "wedding-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Que no pare la barra!"])},
        "wedding-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Que se besen! ¡Que se besen!"])},
        "it-was-amazing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Fue increíble! ¡Gracias por venir!"])}
      },
      "ca": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregant bodorrio"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29 de Juny de 2024"])},
        "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queden"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dies"])},
        "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hores"])},
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuts"])},
        "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i"])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segons"])},
        "wedding-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí! Vull!"])},
        "wedding-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho estem gaudint!"])},
        "wedding-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Queremos que la Lore nos baile la pelusa!"])},
        "wedding-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que no pari la barra!"])},
        "wedding-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Que se besen! ¡Que se besen!"])},
        "it-was-amazing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Va ser impressionant! Gràcies per venir!"])}
      }
    }
  })
}
