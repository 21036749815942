import MainPage from "@/pages/MainPage.vue";
import RegistrationPage from "@/pages/RegistrationPage.vue";
import RegistrationOverPage from "@/pages/RegistrationOverPage.vue";
import PresentPage from "@/pages/PresentPage.vue";
import {createRouter, createWebHistory} from "vue-router";
import LogoutPage from "@/pages/LogoutPage.vue";
import RegistrationOkPage from "@/pages/RegistrationOkPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import AccomodationPage from "@/pages/AccomodationPage.vue";

const routes = [
    {path: '/', component: MainPage},
    {path: '/registration', component: RegistrationPage},
    {path: '/registration-over', component: RegistrationOverPage},
    {path: '/registration-ok', component: RegistrationOkPage},
    {path: '/present', component: PresentPage},
    {path: '/accomodations', component: AccomodationPage},
    {path: '/logout', component: LogoutPage},
    {path: '/login', component: LoginPage},
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

export default router