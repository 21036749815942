<template>
  <router-view/>
</template>

<script>

import {store} from "@/store";

export default {
  name: 'App',
  mounted() {
    this.$root.$i18n.locale = store.state.language;
  }
}
</script>

<style lang="scss">
@import "assets/scss/variables";
@import "assets/scss/fonts";
@import "assets/scss/components";

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  background-color: $beige;
  color: $peach;
}
</style>