export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La masía Can Macià nos indica que queda totalmente prohibido el uso de arroz, confeti y todo tipo de fuegos artificiales."])}
      },
      "ca": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atenció"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La masia Can Macià ens indica que queda totalment prohibit l'ús d'arròs, confeti i tot tipus de focs artificials."])}
      }
    }
  })
}
