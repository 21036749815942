export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de invitación"])},
        "phrase-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, no hemos podido reconocerte."])},
        "phrase-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pero todo tiene solución. Por favor, introduce tu código de invitación."])},
        "invitation-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de invitación"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])},
        "error-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Ups! Tu código de invitación sigue siendo incorrecto... Por favor, contacta con nosotros."])}
      },
      "ca": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi d'invitació"])},
        "phrase-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, no t'hem pogut reconèixer."])},
        "phrase-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Però tot té solució. Si us plau, introdueix el teu codi d'invitació."])},
        "invitation-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi d'invitació"])},
        "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedir"])},
        "error-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups! El teu codi segueix sent incorrecte... Si us plau, contacta amb nosaltres."])}
      }
    }
  })
}
