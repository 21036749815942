export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoteles Cercanos"])},
        "hotel-1-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Somiatruites"])},
        "hotel-1-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrer del Sol 19, Igualada (a 11 min en coche)"])},
        "hotel-1-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes reservar por teléfono al 93 803 66 26 o desde su"])},
        "hotel-1-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio aproximado: 125€/noche"])},
        "hotel-2-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Robert"])},
        "hotel-2-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av. Catalunya 1, La Pobla de Claramunt (a 11 min en coche)"])},
        "hotel-2-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes reservar por teléfono al 93 808 60 00 o desde"])},
        "hotel-2-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio aproximado: 95€/noche"])},
        "hotel-3-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Bruc"])},
        "hotel-3-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autopista A-2 km 570, El Bruc (a 11 min en coche)"])},
        "hotel-3-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes reservar por"])},
        "hotel-3-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio aproximado: 76€/noche"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
        "webpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["página web"])}
      },
      "ca": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotels Propers"])},
        "hotel-1-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Somiatruites"])},
        "hotel-1-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrer del Sol 19, Igualada (a 11 min en cotxe)"])},
        "hotel-1-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots reservar per telèfon al 93 803 66 26 o des de la seva"])},
        "hotel-1-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preu aproximat: 125€/nit"])},
        "hotel-2-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Robert"])},
        "hotel-2-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Av. Catalunya 1, La Pobla de Claramunt (a 11 min en cotxe)"])},
        "hotel-2-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots reservar per telèfon al 93 808 60 00 o des de"])},
        "hotel-2-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preu aproximat: 95€/nit"])},
        "hotel-3-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Bruc"])},
        "hotel-3-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autopista A-2 km 570, El Bruc (a 11 min en cotxe)"])},
        "hotel-3-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pots reservar per"])},
        "hotel-3-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preu aproximat: 76€/nit"])},
        "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking"])},
        "webpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pàgina web"])}
      }
    }
  })
}
