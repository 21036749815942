<template>
  <div class="address-section">
    <div class="column">
      <div class="circle" :class="{bigger: isTwoWeeksToWedding}">
        <div class="icon icon-highway"></div>
        <h1>{{ $t('address') }}</h1>
        <div class="address">
          <div class="address-item">{{ $t('a2-exit') }}</div>
          <div class="address-item">{{ $t('bv-1106') }}</div>
          <div class="address-item">{{ $t('odena') }}</div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="circle" :class="{bigger: isTwoWeeksToWedding}">
        <div class="icon icon-pin"></div>
        <h1>{{ $t('how-to-get-there') }}</h1>
        <p>{{ $t('place-name') }}</p>
        <p>
          <a class="button" href="https://goo.gl/maps/ZAf3cpNQE81D5nAs6?coh=178573&entry=tt" target="_blank">
            {{ $t('map') }}
          </a>
        </p>
      </div>
    </div>
    <div class="column">
      <div class="circle" :class="{bigger: isTwoWeeksToWedding}">
        <div class="icon icon-calendar"></div>
        <h1>{{ $t('date-title') }}</h1>
        <p>
          {{ $t('date') }}<br/>
          {{ $t('time') }}
        </p>
        <WeatherComponent v-if="isTwoWeeksToWedding"/>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "es": {
    "date-title": "Fecha y hora",
    "date": "Sábado 29 de junio de 2024",
    "time": "17:30",
    "how-to-get-there": "¿Cómo llegar?",
    "place-name": "Can Macià - Cavas Bohigas",
    "map": "Mapa",
    "address": "Dirección",
    "a2-exit": "A-2, Salida 557",
    "bv-1106": "Carretera BV-1106, km 2",
    "odena": "08711 Òdena (Barcelona)"
  },
  "ca": {
    "date-title": "Data i hora",
    "date": "Dissabte 29 de juny de 2024",
    "time": "17:30",
    "how-to-get-there": "Com arribar?",
    "place-name": "Can Macià - Caves Bohigas",
    "map": "Mapa",
    "address": "Adreça",
    "a2-exit": "A-2, Sortida 557",
    "bv-1106": "Carretera BV-1106, km 2",
    "odena": "08711 Òdena (Barcelona)"
  }
}
</i18n>

<script>
import WeatherComponent from "@/components/WeatherComponent.vue";

export default {
  components: {WeatherComponent},
  data() {
    return {
      currentTime: (new Date()).getTime(),
      weddingTime: (new Date('2024-06-29T17:30:00')).getTime(),
    }
  },
  computed: {
    remainingTime() {
      return this.weddingTime - this.currentTime;
    },
    isTwoWeeksToWedding() {
      console.log(this.remainingTime);
      return this.remainingTime < 1209600000;
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/breakpoints";

.address-section {
  display: flex;
  width: 100%;
  gap: 64px;
  text-align: center;
  margin-top: 2em;

  @include lg {
    flex-direction: column;
    gap: 32px;
  }

  .column {
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;

    .circle {
      display: inline-block;
      background-color: $beigeLight;
      width: 280px;
      height: 280px;
      border-radius: 30px;
      border: 1px solid $peach;
      font-weight: bold;

      &.bigger {
        height: 300px;
      }
    }

    .icon {
      margin-top: 36px;
      display: inline-block;
      width: 64px;
      height: 64px;

      &.icon-calendar {
        background-image: url('@/assets/img/calendar.png');
      }

      &.icon-pin {
        background-image: url('@/assets/img/pin.png');
      }

      &.icon-windmill {
        background-image: url('@/assets/img/windmill.png');
      }

      &.icon-highway {
        background-image: url('@/assets/img/highway.png');
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 3px;
    }
  }
}
</style>