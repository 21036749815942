<template>
    <div class="radio-option" :class="{selected: selected}" @click="select">
        {{ option.label }}
    </div>
</template>

<script>
export default {
    props: {
        option: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        select() {
            this.$emit('select', this.option.value)
        }
    }
}
</script>

<style scoped lang="scss">

</style>