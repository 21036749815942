export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Asistencia"])},
        "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver fotos"])}
      },
      "ca": {
        "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Assistència"])},
        "gallery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure fotos"])}
      }
    }
  })
}
