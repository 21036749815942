<template>
  <div class="characters" :style="{transform: 'translateY(-' + positionY + 'px)'}">
    <div class="lorena" :style="{backgroundPositionY: backgroundOffsetY + 'px'}"></div>
    <div class="carles" :style="{backgroundPositionY: backgroundOffsetY + 'px'}"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      elapsedTime: 0,
      interval: null,
    }
  },
  computed: {
    positionY() {
      if (this.isSm()) {
        return Math.max(0, Math.sin(this.elapsedTime) * 25);
      }

      return Math.max(0, Math.sin(this.elapsedTime) * 50);
    },
    backgroundOffsetY() {
      return this.positionY === 0 ? (this.isSm() ? 128 : 256) : 0;
    },
  },
  mounted() {
    if (this.interval === null) {
      this.interval = setInterval(() => {
        this.elapsedTime += 50;
      }, 50);
    }
  },
  unmounted() {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  methods: {
    isSm() {
      return screen.width <= 576;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/breakpoints";

.characters {
  margin-top: 1em;
  display: flex;
  justify-content: center;

  .lorena {
    width: 256px;
    height: 256px;
    background-image: url('@/assets/img/novios.png');
    background-position-x: 0;

    @include sm {
      background-size: 200%;
      width: 128px;
      height: 128px;
    }
  }

  .carles {
    margin-left: -48px;
    width: 256px;
    height: 256px;
    background-image: url('@/assets/img/novios.png');
    background-position-x: 256px;

    @include sm {
      background-size: 200%;
      width: 128px;
      height: 128px;
      background-position-x: 128px;
    }
  }
}
</style>