import axios from 'axios';
import addPrivateRequestInterceptors from './interceptors.request';
import addPrivateResponseInterceptors from './interceptors.response';

const axiosFactory = (baseURL) => axios.create({
    baseURL,
    timeout: 30000
});

const apiAxios = axiosFactory(process.env.VUE_APP_API_BASE_URL)
const apiPublicAxios = axiosFactory(process.env.VUE_APP_API_BASE_URL)

const privateInstances = [
    apiAxios,
];

privateInstances.forEach(addPrivateRequestInterceptors);
privateInstances.forEach(addPrivateResponseInterceptors);

export {
    apiAxios,
    apiPublicAxios
};