<template>
  <header-component/>
  <div class="container-small">
    <div class="section">
      <h1>{{ $t('title') }}</h1>
      <p>
        {{ $t('phrase') }}
      </p>
    </div>
    <present-component/>
    <rules-component/>
    <accomodations-component/>
  </div>
</template>

<i18n>
{
  "es": {
    "title": "Muchas gracias",
    "phrase": "Tus datos se han guardado correctamente. Nos vemos pronto :)"
  },
  "ca": {
    "title": "Moltes gràcies",
    "phrase": "Les teves dades s'han guardat correctament. Ens veiem aviat :)"
  }
}
</i18n>

<script>
import PresentComponent from "@/components/PresentComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import AccomodationsComponent from "@/components/AccomodationsComponent.vue";
import RulesComponent from "@/components/RulesComponent.vue";

export default {
  components: {RulesComponent, AccomodationsComponent, HeaderComponent, PresentComponent}
}
</script>

<style scoped lang="scss">

</style>