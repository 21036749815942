// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/logout.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/img/logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".intro-section[data-v-f48fe79c]{background-color:#eddbc7;color:#744f57;text-align:center;overflow:hidden;padding:4em 0 2em 0}.intro-section .username[data-v-f48fe79c]{align-items:center;gap:8px;position:absolute;top:16px;right:32px;color:#a7727d;display:none}@media(max-width:576px){.intro-section .username[data-v-f48fe79c]{display:inline-flex;font-size:14px;top:18px;right:64px;z-index:3}}.intro-section .username .logout-icon[data-v-f48fe79c]{width:24px;height:24px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;cursor:pointer}@media(max-width:576px){.intro-section .username .logout-icon[data-v-f48fe79c]{width:18px;height:18px;font-size:14px}}.intro-section .logo[data-v-f48fe79c]{width:100%;height:84px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-size:contain;background-repeat:no-repeat}@media(max-width:576px){.intro-section .logo[data-v-f48fe79c]{height:48px}}.intro-section h2[data-v-f48fe79c]{font-size:20px;font-weight:300;text-transform:uppercase;letter-spacing:3px;margin:2em 0}@media(max-width:576px){.intro-section h2[data-v-f48fe79c]{font-size:16px}}.intro-section h3[data-v-f48fe79c]{font-size:20px;font-weight:300;margin:2em 0;display:none}@media(max-width:576px){.intro-section h3[data-v-f48fe79c]{display:block}}.intro-section .auth-error[data-v-f48fe79c]{max-width:1000px;margin:10px auto}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
