<template>
  <header-component/>
  <div class="container-small login-content">
    <div class="section">
      <h1>{{$t('title')}}</h1>
      <div class="login-content">
        <div class="icon-column">
          <div class="icon icon-confirmation"></div>
        </div>
        <div class="text-column">
          <p>{{$t('phrase-1')}}</p>
          <p>{{$t('phrase-2')}}</p>
          <div class="form-input">
            <label>
              {{$t('invitation-code')}}
              <input v-model="code" type="text" maxlength="16"/>
            </label>

            <div class="save">
              <characters-loading v-if="loading"/>
              <button @click="login" :class="{disabled: loading}" :disabled="loading">{{$t('submit')}}</button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="invitationError" class="alert auth-error">
        {{$t('error-invalid')}}
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "es": {
    "title": "Código de invitación",
    "phrase-1": "Lo sentimos, no hemos podido reconocerte.",
    "phrase-2": "Pero todo tiene solución. Por favor, introduce tu código de invitación.",
    "invitation-code": "Código de invitación",
    "submit": "Acceder",
    "error-invalid": "¡Ups! Tu código de invitación sigue siendo incorrecto... Por favor, contacta con nosotros."
  },
  "ca": {
    "title": "Codi d'invitació",
    "phrase-1": "Ho sentim, no t'hem pogut reconèixer.",
    "phrase-2": "Però tot té solució. Si us plau, introdueix el teu codi d'invitació.",
    "invitation-code": "Codi d'invitació",
    "submit": "Accedir",
    "error-invalid": "Ups! El teu codi segueix sent incorrecte... Si us plau, contacta amb nosaltres."
  }
}
</i18n>

<script>
import CharactersLoading from "@/components/CharactersLoading.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import {apiPublicAxios} from "@/service/apiClient";
import {store} from "@/store";

export default {
  components: {HeaderComponent, CharactersLoading},
  data() {
    return {
      code: '',
      invitationError: false,
      loading: false,
    }
  },
  methods: {
    login() {
      if(this.code === '') {
        return;
      }

      this.invitationError = false;
      apiPublicAxios.get('/login/' + this.code.toUpperCase())
        .then(data => {
          store.commit('setAuth', data.data.token)
          store.commit('setAuthError', null)
          this.$root.$i18n.locale = store.state.language;
          this.$router.push('/registration')
        })
        .catch(error => {
          this.invitationError = true;
          console.error(error);
          store.commit('setAuthError', error)
        })
    }
  },
}
</script>

<style scoped lang="scss">
.login-content {
  display: flex;
  gap: 1em;
  align-items: normal;

  .icon-column {
    flex-grow: 0;
    flex-shrink: 0;

    .icon {
      display: inline-block;
      width: 128px;
      height: 128px;

      &.icon-confirmation {
        background-image: url('@/assets/img/confirmation.png');
      }
    }
  }

  .save {
    display: flex;
    align-items: center;
    justify-content: right;
  }
}
</style>