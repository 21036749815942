<template>
  <div class="section">
    <h1>{{$t('title')}}</h1>
    <div class="rules-content">
      <div class="text-column">
        <p>{{$t('text')}}</p>
      </div>
      <div class="icon-column">
        <div class="icon icon-prohibitions"></div>
      </div>
    </div>

  </div>
</template>

<i18n>
{
  "es": {
    "title": "Atención",
    "text": "La masía Can Macià nos indica que queda totalmente prohibido el uso de arroz, confeti y todo tipo de fuegos artificiales."
  },
  "ca": {
    "title": "Atenció",
    "text": "La masia Can Macià ens indica que queda totalment prohibit l'ús d'arròs, confeti i tot tipus de focs artificials."
  }
}
</i18n>

<script>

export default {
  computed: {

  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/breakpoints";

.rules-content {
  display: flex;
  gap: 1em;
  align-items: center;

  .icon-column {
    flex-grow: 0;
    flex-shrink: 0;

    .icon {
      display: inline-block;
      width: 128px;
      height: 128px;


      &.icon-prohibitions {
        background-image: url('@/assets/img/prohibitions.png');
      }
    }
  }

  .text-column {
    p:first-child {
      margin-top: 0;
    }
  }
}
</style>