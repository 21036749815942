<template>
  <div class="timeline-container">
    <div class="timeline">
      <div class="timeline-line"></div>
      <div class="timeline-item timeline-item-left">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-cocktail"></div>
          <div class="text">
            <h1>{{ $t('cocktail') }}</h1>
            <h2>17:30</h2>
          </div>
        </div>
      </div>
      <div class="timeline-item timeline-item-right">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-arch"></div>
          <div class="text">
            <h1>{{ $t('ceremony') }}</h1>
            <h2>18:00</h2>
          </div>
        </div>
      </div>
      <div class="timeline-item timeline-item-left">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-rings"></div>
          <div class="text">
            <h1>{{ $t('yes') }}</h1>
            <h2>18:30</h2>
          </div>
        </div>
      </div>
      <div class="timeline-item timeline-item-right">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-appetizer"></div>
          <div class="text">
            <h1>{{ $t('appetizer') }}</h1>
            <h2>19:00</h2>
          </div>
        </div>
      </div>
      <div class="timeline-item timeline-item-left">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-dinner"></div>
          <div class="text">
            <h1>{{ $t('dinner') }}</h1>
            <h2>21:00</h2>
          </div>
        </div>
      </div>
      <div class="timeline-item timeline-item-right">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-cake"></div>
          <div class="text">
            <h1>{{ $t('cake') }}</h1>
            <h2>23:00</h2>
          </div>
        </div>
      </div>
      <div class="timeline-item timeline-item-left">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-disco"></div>
          <div class="text">
            <h1>{{ $t('dj') }}</h1>
            <h2>0:00</h2>
          </div>
        </div>
      </div>
      <div class="timeline-item timeline-item-right">
        <div class="content">
          <div class="line-item"></div>
          <div class="icon icon-lorena-drunk"></div>
          <div class="text">
            <h1>{{ $t('game-over') }}</h1>
            <h2>3:00</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "es": {
    "cocktail": "Cóctel de bienvenida",
    "ceremony": "Ceremonia civil",
    "yes": "Sí, quiero",
    "appetizer": "Aperitivos",
    "dinner": "Cena",
    "cake": "Tarta nupcial",
    "dj": "Barra libre + DJ",
    "game-over": "Fin de fiesta",
  },
  "ca": {
    "cocktail": "Còctel de benvinguda",
    "ceremony": "Cerimònia civil",
    "yes": "Sí, vull",
    "appetizer": "Aperitius",
    "dinner": "Sopar",
    "cake": "Pastís nupcial",
    "dj": "Barra lliure + DJ",
    "game-over": "Fi de festa"
  }
}
</i18n>

<script>
export default {}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";
@import "@/assets/scss/breakpoints";

.timeline-container {
  margin-bottom: 4em;
  
  .timeline {
    position: relative;
    overflow: hidden;

    .timeline-line {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 1px;
      height: calc(100% - 48px);
      background: $peach;

      @include md {
        height: calc(100% - 152px);
      }
    }

    .timeline-item {
      display: flex;
      margin-top: 4em;

      &.timeline-item-left {
        justify-content: start;

        .line-item {
          position: absolute;
          right: -48px;
          width: 32px;
          height: 1px;
          background: $peach;

          @include md {
            top: 48px;
            right: 0;
          }
        }

        .content {
          display: flex;
          flex-direction: row-reverse;

          @include md {
            flex-direction: column;
          }

          .text {
            text-align: right;

            @include md {
              text-align: center;
            }
          }
        }
      }

      &.timeline-item-right {
        justify-content: end;

        .line-item {
          position: absolute;
          left: -48px;
          width: 32px;
          height: 1px;
          background: $peach;

          @include md {
            top: 48px;
            left: 0;
          }
        }

        .text {
          h1 {
            padding-right: 16px;

            @include md {
              padding-right: 0;
            }
          }
        }
      }

      .content {
        display: flex;
        align-items: center;
        flex-basis: calc(50% - 48px);
        border-radius: 30px;
        position: relative;

        @include md {
          flex-direction: column;
          height: 200px;
          flex-basis: 50%;
        }

        .icon {
          flex-shrink: 0;
          width: 64px;
          height: 64px;
          margin: 1em;

          &.icon-cocktail {
            background-image: url('@/assets/img/cocktail.png');
          }

          &.icon-arch {
            background-image: url('@/assets/img/arch.png');
          }

          &.icon-rings {
            background-image: url('@/assets/img/rings.png');
          }

          &.icon-appetizer {
            background-image: url('@/assets/img/appetizer.png');
          }

          &.icon-dinner {
            background-image: url('@/assets/img/dinner.png');
          }

          &.icon-cake {
            background-image: url('@/assets/img/cake.png');
          }

          &.icon-disco {
            background-image: url('@/assets/img/disco.png');
          }

          &.icon-lorena-drunk {
            background-image: url('@/assets/img/lorena_drunk.png');
          }
        }

        .text {
          @include md {
            margin: 0 1em;
            text-align: center;
          }

          h1 {
            font-size: 20px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: 3px;

            @include sm {
              font-size: 16px;
            }

            @include xs {
              font-size: 12px;
            }
          }

          h2 {
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      + .timeline-item {
        margin-top: -1em;

        @include md {
          margin-top: -4em;
        }
      }
    }
  }
}
</style>