<template>
  <div class="section">
    <h1>{{ guest.name }}</h1>
    <form>
      <div class="form-input">
        <label>{{ guest.name }}{{ $t('will-you-come') }}</label>
        <radio-options :options="options" v-model="localComing" @update:model-value="onChangeComing"/>
      </div>
      <template v-if="localComing">
        <div class="form-input">
          <label>
            {{ $t('full-name') }}
            <input v-model="localFullName" :class="{error: errorFullName}" @input="onChangeFullName($event)" type="text"
                   maxlength="255"/>
          </label>
          <div v-if="errorFullName" class="form-error">
            {{ $t('error-full-name') }}
          </div>
        </div>
        <div class="form-input">
          <label>
            {{ $t('allergies') }}
            <input v-model="localAllergies" @input="onChangeAllergies($event)" type="text" maxlength="255"/>
          </label>
        </div>
        <div class="form-input">
          <label>
            {{ $t('note') }}
            <textarea v-model="localNote" @input="onChangeNote($event)" rows="4"></textarea>
          </label>
        </div>
      </template>
    </form>
  </div>
</template>

<i18n>
{
  "es": {
    "will-you-come": ", ¿vendrás?",
    "full-name": "Nombre completo",
    "allergies": "¿Tienes alguna alergia, intolerancia o dieta especial?",
    "note": "¿Quieres comentarnos algo más?",
    "coming-yes": "Sí",
    "coming-no": "No",
    "coming-dont-know": "Todavía no lo sé",
    "error-full-name": "Por favor, introduce tu nombre completo para poder continuar"
  },
  "ca": {
    "will-you-come": ", vindràs?",
    "full-name": "Nom complet",
    "allergies": "Tens alguna al·lèrgia, intol·lerància o dieta especial?",
    "note": "Vols dir-nos alguna cosa més?",
    "coming-yes": "Sí",
    "coming-no": "No",
    "coming-dont-know": "Encara no ho sé",
    "error-full-name": "Si us plau, introdueix el teu nom complet per a poder continuar"
  }
}
</i18n>

<script>
import RadioOptions from "@/components/RadioOptions.vue";

export default {
  components: {RadioOptions},
  props: {
    guest: {
      type: Object,
      required: true,
    },
    fullName: {
      type: String,
      required: true,
    },
    coming: {
      required: true,
    },
    allergies: {
      type: String,
      required: true,
    },
    note: {
      type: String,
      required: true,
    },
    errorFullName: {
      type: Boolean,
      required: true,
    }
  },
  emits: [
    'update:fullName',
    'update:coming',
    'update:allergies',
    'update:note',
  ],
  data() {
    return {
      options: [
        {label: this.$t('coming-yes'), value: true},
        {label: this.$t('coming-no'), value: false},
        {label: this.$t('coming-dont-know'), value: null},
      ],
      localFullName: this.fullName,
      localComing: this.coming,
      localAllergies: this.allergies,
      localNote: this.note,
    }
  },
  methods: {
    onChangeFullName(ev) {
      this.$emit('update:fullName', ev.target.value)
    },
    onChangeComing(ev) {
      this.$emit('update:coming', ev)
    },
    onChangeAllergies(ev) {
      this.$emit('update:allergies', ev.target.value)
    },
    onChangeNote(ev) {
      this.$emit('update:note', ev.target.value)
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables";

.form-error {
  color: $red;
  font-size: 12px;
  margin-bottom: 1em;
  margin-top: -1em;
}
</style>